<template>
  <div>
    <b-modal
      id="modal-edit-satuan-brg"
      size="lg"
      centered
      title="Edit Data Master Satuan"
      header-bg-variant="warning"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Satuan <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_satuan')"
            type="text"
            v-model="$v.is_data.nama_satuan.$model"
            @change="addKey('nama_satuan')"
          ></b-form-input>
        </b-form-group>
      </b-form>

      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-satuan-brg')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "modalEdit",
  props: ["data_edit", "ep_be"],
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      is_edited: {},
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      nama_satuan: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      if (vm.isValid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        // console.log(vm.is_edited);
        vm.$axios
          .post(this.ep_be + "/update", vm.is_edited)
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH DATA",
                showing: true,
              });
              vm.$bvModal.hide("modal-edit-satuan-brg");
              vm.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: _.toUpper(res.data.message),
                showing: true,
              });
            }
          })
          .catch(() => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      }
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        nama_satuan: "",
      };
      this.is_edited = {}
    },
    addKey(key) {
      if(!("id" in this.is_edited)) {
        this.is_edited.id = this.is_data.ms_satuan_barang_id
      }
      this.is_edited[key] = this.is_data[key]
    }
  },
};
</script>
